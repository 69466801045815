* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.container_sidebar {
  display: flex;
}
main {
  width: 100%;
  /* padding-top: 20px; */
  /* overflow: auto; */
}
.sidebar {
  background: linear-gradient(260deg, #16b0c2, #0fc4b8);
  color: #fff;
  height: auto;
  width: 200px;
  transition: all 0.5s;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}
.logo {
  font-size: 30px;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}
.Nav_link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}
.Nav_link:hover {
  background: lightskyblue;
  color: #1aa4c8;
  transition: all 0.5s;
}
.active {
  background: lightskyblue;
  color: #1aa4c8;
}
.icons,
.link_text {
  font-size: 20px;
}

/* =================dashboard================ */
/* .image_posme{
  position: absolute;
  top: 30px;
  right:70px;
} */
.image_pos {
  position: absolute;
  top: 6px;
  right: 2px;
}
.image_posdown {
  position: absolute;
  top: 88px;
  right: 3px;
}
.cardFooter {
  background: linear-gradient(260deg, #0fc3b8, #15b0c1) !important;
}
/* =================collection================= */
.card_border {
  position: relative;
  top: 12px;
  width: 100%;
}
.Actcard {
  border-width: 0px 0px 3px 0px !important;
  border-color: #00d5ae !important;
  padding-top: 8px !important;
}
.tbl_crp {
  cursor: pointer;
  color: #42a5f5 !important;
}
.active-card {
  background: #c3d3e2 !important;
  /* color: white !important; */
}
/* =====================analytics=================== */
.custom_placeholder::placeholder {
  color: gray !important;
}
/* =======================login===================== */
.BTN_Color {
  background: linear-gradient(260deg, #1aa4c8, #00d5ae);
}

/* ================progress bar color change code============== */

.custom-progress-bar .progress-bar {
  /* background-color: linear-gradient(260deg, #0FC3B8, #15B0C1)!important;  */
  background-color: #0fc3b8 !important;
}

/* =====spinner code==== */
#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(233, 241, 241, 0.7);
  z-index: 9999;
  display: none;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #15b0c1;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

/* =====landing code==== */
@media (max-width: 425px) {
  .bg_res h3 {
    font-size: 35px !important;
  }
  .bg_res h2 {
    font-size: 50px !important;
    line-height: normal !important;
  }
  .logo_smnone img{
    /* width: 110px !important;
    height: 110px !important; */
    display: none !important;
  }
  .sm_res{
   width: auto !important;
  }
}
.carousel_bg{
  background-color: #EEEEF3;
  height: 740px;
  display: flex;
  align-items: center;
  justify-content: center;
}