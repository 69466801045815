body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f6f9;
}

.main-wrapper {
  display: flex;
  align-items: center;

}

.vertical-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 20px;
  font-weight: bold;
  color: #0b163a;
  margin-right: 20px;
}

.table-wrapper {
  flex-grow: 1;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  background-color: #fff;
  border-radius: 8px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  /* text-align: center; */
}

.input-field {
  width: 100px;
  height: 30px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px; /* Optional: for rounded corners */
}

.btn {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn.success {
  background-color: #1DCC63;
  color: white;
}

.btn.pending {
  background-color: #6D9EE9;
  color: white;
}

.btn.declined {
  background-color: #FF4D4D;
  color: white;
}
